/* eslint-disable no-param-reassign,operator-linebreak */
/* eslint-disable arrow-body-style */
/* This is the Root component mainly initializes Redux and React Router. */
import eurekaMgrs from '@eureka/ui-managers';
import '@eureka/ui-managers/src/styles/layout.css';
import '@ui5/webcomponents-icons/dist/Assets-static';
import { ThemeProvider, MessageStrip, MessageStripDesign } from '@ui5/webcomponents-react';
import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme';
import '@ui5/webcomponents/dist/features/InputSuggestions.js';
import { setLanguage as setUi5Language } from '@ui5/webcomponents-base/dist/config/Language';
import eurekaComponents from 'eureka';
import React, { useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import { getURLParam, TestingLocales } from './common/Utils';
import { mergeSettings, renderRouteConfigV3 } from './App.helper';
import history from './common/history';
import routeConfig from './common/routeConfig';
import store from './common/store';
import { MicroFrontend } from './features/common';
import { getFeatureToggle } from './features/common/UserProfile/eureka';

const { MessageToast } = eurekaComponents.controls;
const { initI18n, setLanguage, getUi5Language } = eurekaComponents.I18nProvider;
const { Spinner } = eurekaComponents.components;
const { addConfig, setCsrfToken, getConfig, updateFeatureToggle } = eurekaMgrs.ConfigManager;
const configManagerSetLanguage = eurekaMgrs.ConfigManager.setLanguage;

const { eventBus } = eurekaMgrs;

let config = null;
let lng = 'en-US';

// eslint-disable-next-line arrow-body-style
export const renderError = (msg) => {
  return (
    <MessageStrip
      data-testid="error-message-strip"
      style={{ marginTop: '10px', marginRight: '10px' }}
      type={MessageStripDesign.Negative}
      icon="error"
      hideCloseButton
    >
      {msg}
    </MessageStrip>
  );
};

export const MicroFrontendWrapper = ({ _history, match, host, name, settings, user }) => {
  if (!settings) {
    console.error('Settings for microfrontends is empty, which is not allowed');
    return null;
  }
  return (
    <MicroFrontend
      history={_history}
      match={match}
      host={host}
      name={name}
      config={config}
      settings={settings}
      user={user.current}
      eventBus={eventBus}
    />
  );
};

// eslint-disable-next-line no-unused-vars
export const isAuthorized = (appName, _getConfig, _getFeatureToggle) => {
  const permissions = _getConfig('CurrentUserPermissions') || [];
  switch (appName) {
    case 'mfe-login':
      return true;
    case 'osta-gap-check':
      return permissions?.length > 0 && ['GAPCHECK.READ'].every((p) => permissions.includes(p));
    case 'osta-sales-audit':
      return (
        permissions?.length > 0 && ['POSTRANSACTION.READ'].every((p) => permissions.includes(p))
      );
    case 'osta-change-log':
      return (
        permissions?.length > 0 && ['CHANGELOGEVENT.READ'].every((p) => permissions.includes(p))
      );
    case 'osta-outbound':
      return permissions.includes('AGGREGATION.READ');
    case 'osta-configuration':
      // todo: add TypeCodeConfig specific permission
      return ['ADJUSTMENTSCONFIG.READ', 'AGGREGATIONCONFIG.READ'].some((p) =>
        permissions.includes(p),
      );
    default:
      return false;
  }
};

export const renderMicroFrontendRoutes = ({
  mfdRouters,
  history: _history,
  settings,
  user,
  isAuthorized: _isAuthorized,
}) => {
  const routes = [];
  mfdRouters.forEach((app) => {
    if (_isAuthorized(app.name, eurekaMgrs.ConfigManager.getConfig, getFeatureToggle)) {
      app.routers.forEach((path) => {
        routes.push(
          <Route
            key={app.name + crypto.randomUUID()}
            exact
            path={path}
            Component={(props) => (
              <MicroFrontendWrapper
                {...props}
                host={app.host}
                name={app.name}
                history={_history}
                settings={settings}
                user={user}
              />
            )}
          />,
        );
      });
    }
  });
  return routes;
};

// eslint-disable-next-line consistent-return
const onFetchConfigSuccess = ({ manifest, state, setState, setMicroFrontends }) => {
  const shell = eurekaComponents.I18nProvider.getLocation(manifest, manifest['shell-ui']);
  const microFrontends = [];

  manifest.components.forEach((component) => {
    const host = eurekaComponents.I18nProvider.getLocation(manifest, component);

    microFrontends.push({
      name: component.config.app,
      host,
      routers: component.config.routers,
    });
  });

  config = manifest;
  setState({
    ...state,
    config,
  });

  setMicroFrontends(microFrontends);

  // add app config into config manager
  addConfig('appConfig', config);

  // init i18n
  // i18next configuration: https://www.i18next.com/overview/configuration-options
  initI18n(
    {
      shell,
    },
    {
      debug: process.env.NODE_ENV === 'production',
      lowerCaseLng: false,
      fallbackLng: 'en',
      fallbackNS: 'shell',
      whitelist: false,
      lng, // en-US en-US-sappsd
      load: 'currentOnly',
      defaultNS: 'shell',
      ns: 'shell',
      preload: [lng], // en-US en-US-sappsd
      react: {
        useSuspense: false,
        wait: false,
      },
    },
  );
  // Handle error page
  if (window.location.pathname.startsWith('/error')) {
    return setState({
      ...state,
      initializing: false,
    });
  }
};

const renderInitializing = () => {
  return (
    <div
      className="app-loading"
      data-testid="app-loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner className="page-load-spinner" />
    </div>
  );
};

const onFetchAuthSuccess = ({ auth, user }) => {
  window.hasLoggedin = true;
  if (auth?.data) {
    addConfig('user', auth?.data);
  }
  // eslint-disable-next-line no-param-reassign
  user.current = auth?.data;
};

const onFetchCsrfFailed = ({ error, user, state, setState }) => {
  MessageToast.error('Failed to get CSRF token, please contact System Administrator!');
  console.log(`${error}`);
  // set a fake csrf token
  setCsrfToken('fakecsrftoken');
  setState({
    ...state,
    settings: {},
    user: user.current,
    fetchConfigError: null,
  });
};

export const onFetchSettingsFinally = ({ rawSettings, state, setState }) => {
  const testingLng = getURLParam(window.location.search, 'sap-language');
  if (testingLng) {
    lng = TestingLocales[testingLng] ? TestingLocales[testingLng] : testingLng;
  }

  // set new language
  setLanguage(lng);
  setUi5Language(getUi5Language(lng));
  configManagerSetLanguage(lng);
  if (Object.keys(rawSettings.current?.basicSetup) <= 0) {
    setTimeout(() => {
      MessageToast.error('Shell_LoadSettingFailed');
    }, 0);
  }
  // set initialization done
  setState({
    ...state,
    initializing: false,
  });
};

const getDataFromResults = ({ results, index, defValue = {} }) => {
  return Array.isArray(results) && results.length > index && results[index]?.data
    ? results[index]?.data
    : defValue;
};

const onFetchSettingsSuccess = ({ results, rawSettings, state, setState, user }) => {
  // Uncomment the API calls first
  // rawSettings.current = {
  //   ...rawSettings.current,
  //   basicSetup: getDataFromResults({ results, index: 0 }),
  //   userProfile: getDataFromResults({ results, index: 1 }),
  //   companyProfile: getDataFromResults({ results, index: 2 }),
  // };
  const userProfile = getDataFromResults({ results, index: 0 });
  userProfile.timeZone = userProfile?.profileTimeZone || 'America/Los_Angeles';

  rawSettings.current = {
    ...rawSettings.current,
    basicSetup: getDataFromResults({ results, index: 0 }),
    userProfile,
    companyProfile: getDataFromResults({ results, index: 1 }),
  };
  const settings = mergeSettings(rawSettings.current);
  const currentUser = getDataFromResults({ results, index: 0 });
  const { roles: userPermissions } = getDataFromResults({ results, index: 1 });
  addConfig(
    'CurrentUserPermissions',
    userPermissions.map((p) => p.toUpperCase()),
  );

  addConfig('CurrentUser', currentUser);

  // eslint-disable-next-line max-len
  // the next line will make accessible the feature flags to the micro-frontend through the config manager
  updateFeatureToggle(getDataFromResults({ results, index: 2 }).resultList);
  if (rawSettings.current.userProfile && rawSettings.current.userProfile.language) {
    lng = rawSettings.current.userProfile.language;
  } else if (rawSettings.current.basicSetup && rawSettings.current.basicSetup.language) {
    lng = rawSettings.current.basicSetup.language;
  }

  user.current.databaseUserId = rawSettings.current.basicSetup.id;
  setState({
    ...state,
    settings,
    user: rawSettings.current.userProfile,
  });
};

export const onFetchAuthFailed = ({ error, state, setState, redirectUrl }) => {
  if (window.location.href.indexOf(redirectUrl) < 0 && error.request.status === 401) {
    window.location.href = redirectUrl;
  } else if (window.location.href.indexOf(redirectUrl) < 0 && error.request.status !== 401) {
    window.hasLoggedin = false;
    setState({ ...state, authUserError: error });
  } else {
    window.hasLoggedin = false;
    console.log(`Auth user error: ${error}`);
    setState({
      ...state,
      initializing: false,
    });
  }
};

export const renderMfes = ({ state, user, microFrontends, isAuthorized: _isAuthorized }) => {
  const containerRoutes = renderRouteConfigV3(routeConfig, '/', config, state.settings, user);
  const microFrontendRoutes = renderMicroFrontendRoutes({
    mfdRouters: microFrontends,
    history,
    settings: state.settings,
    user,
    isAuthorized: _isAuthorized,
  });
  return (
    <ThemeProvider>
      <Provider store={store}>
        <Router history={history}>
          <Routes>
            {microFrontendRoutes}
            {containerRoutes}
          </Routes>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

const App = ({ fetchConfig, fetchAuth, fetchCsrf, fetchSettings, fetchUserCreate }) => {
  const [state, setState] = useState({
    initializing: true,
    fetchConfigError: false,
    authUserError: false,
    config: null,
    settings: { basicSetup: {}, userProfile: {}, companyProfile: {} },
    user: {},
  });
  const [microFrontends, setMicroFrontends] = useState([]);

  const user = useRef(null);
  const rawSettings = useRef({ basicSetup: {}, userProfile: {}, companyProfile: {} });

  useEffect(() => {
    setTheme('sap_horizon');
    addConfig('application', 'osta-sales-audit');
    fetchConfig().then(
      (result) => {
        const manifest = result.data;
        onFetchConfigSuccess({
          manifest,
          state,
          setState,
          setMicroFrontends,
        });
        fetchAuth().then(
          (auth) => {
            onFetchAuthSuccess({ auth, user });
            fetchCsrf()
              .then(
                (resultCsrf) => setCsrfToken(resultCsrf?.data?.token),
                (error) => onFetchCsrfFailed({ error, user, state, setState }),
              )
              .finally(() => {
                fetchUserCreate().then(
                  ({ data }) => {
                    addConfig('user', { ...data, ...auth?.data });
                    user.current = getConfig('user');
                    fetchSettings()
                      .then(
                        (results) => {
                          onFetchSettingsSuccess({
                            results,
                            state,
                            setState,
                            rawSettings,
                            user,
                          });
                        },
                        () =>
                          setState({
                            ...state,
                            settings: {},
                            user: user.current,
                            fetchConfigError: false,
                          }),
                      )
                      .finally(() => onFetchSettingsFinally({ rawSettings, state, setState }));
                  },
                  () =>
                    setState({
                      ...state,
                      settings: {},
                      user: user.current,
                      fetchConfigError: false,
                    }),
                );
              });
          },
          (error) =>
            onFetchAuthFailed({ error: error.response, state, setState, redirectUrl: '/login' }),
        );
      },
      (error) => {
        console.error('Error:', error);
        setState({
          ...state,
          initializing: false,
          fetchConfigError: error,
        });
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.fetchConfigError) {
    return renderError('Failed to load config, please try again.');
  }

  if (state.authUserError) {
    return renderError('Failed to get user information, please refresh page.');
  }

  if (state.initializing) {
    return renderInitializing();
  }

  return renderMfes({ state, user, microFrontends, isAuthorized });
};

export default App;
