/* eslint-disable operator-linebreak */
export const checkPermissions = (item, currentUserPermissions) => {
  // eslint-disable-next-line no-prototype-builtins
  if (item.hasOwnProperty('skipPermission') && item.skipPermission) {
    return true;
  }

  if (item.permissions) {
    const permissions = Array.isArray(item.permissions)
      ? item.permissions.map((p) => p.toUpperCase())
      : [];
    if (item.conjunction && item.conjunction === 'OR') {
      return permissions.length > 0 && permissions.some((p) => currentUserPermissions.includes(p));
    }
    return permissions.length > 0 && permissions.every((p) => currentUserPermissions.includes(p));
  }
  return false;
};
